:root {
  --main-blue: #051D43;
  --secondary-blue: #4178FF;
  --notification-box: #FAF7D0;
}

body {
  height: auto;
  width: 100%;
}

.blue-text {
  color: var(--secondary-blue);
}

.bold {
  font-weight: bold;
}

.App {
  text-align: center;
}

.App-logo {
  height: 35px;
}

.App-header {
  background-color: var(--main-blue);
  height: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-main {
  height: 100%; 
  background-color: #eee;
  padding: 15px 1.5%;
  margin: 0 auto;
  min-height: 90vh;
}

/* Button styles */

.link-w-back-arrow {
  color: var(--secondary-blue);
  font-weight: bold;
  text-decoration: none;
}
.link-w-back-arrow::before {
  content: "<";
  font-size: 12px;
  padding-right: 4px;
}
.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 14px;
}
.back-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  max-width: 1100px;
  margin: 25px auto;
  position: relative;
  left: 15px;
}
.success-btn-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.success-btn-container > a {
  margin-right: 10px;
}
.button {
  color: white;
  background-color: var(--secondary-blue);
  border: 1px solid var(--secondary-blue);
  padding: .65rem 1.75rem;
  min-width: 175px;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
}
.button:hover {
  color: var(--secondary-blue);
  background-color: white;
  text-decoration: none;
}
.button:focus {
  outline: 2px dashed var(--secondary-blue);
}
/* Button styles - END*/

.app-footer {
  height: 33vh;
  color: black;
}

.disabled {
  display: none !important;
  visibility: hidden;
}

.hide-sub-header .search-header {
  display: none !important;
}


/* Table Styles */
table {
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  border-width: 2px;
  border-color: #ffffff;
  border-style: solid;
  color: #000000;
  text-align: left;
  font-size: 14px;
}

table thead {
  background-color: #ffffff;
  border-bottom: 2px solid var(--secondary-blue);
  padding: 3px;
}

table thead tr {

}

table thead tr th {
  padding: 10px;
}

table tbody {
  
}
table tbody tr {
  
}
table tbody tr td {
  padding: 15px 10px;
}
table tbody tr td p {
  margin: 3px 0;
}

table tbody tr:nth-child(odd) {
  background-color: white;
  border: 1px solid rgba(0,0,0,0);
}
table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
  border: 1px solid rgba(0,0,0,0);
}
table tbody tr:nth-child(odd):hover {
  
}
table tbody tr:nth-child(even):hover {
  
}
.border-blue {
  width: 100%;
  height: 2px;
  background-color: var(--secondary-blue);
}
/* Table Styles - END*/

/* search Header */
.search-header {

}

.search-header .search-container {
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin: 0 auto;
  padding: 10px 0;
}

.search-header .search-container .search-wrapper {

}
.search-header .search-container .cta-wrapper {

}

#searchForm input {
  position: relative;
  min-width: 175px;
  left: -10px;
  top: 1px;
}

#searchForm fieldset {
  display: flex;
  flex-direction: row;
}

#searchForm button {
  min-width: auto;
}

/* search Header - END */

/* do not contact */
.do-not-contact-table {

}

.do-not-contact-container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 25px 0;
}
/* do not contact */


/* add new contact */

.add-new-contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  max-width: 1100px;
  margin: 0 auto;
  padding: 25px 0;
  background-color: white;
}

.add-new-contact .add-new-contact-container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 50px 0;
  text-align: left;
}
.add-new-contact .add-new-contact-container h2 {
  padding-left: 15px;
}
.add-new-contact .add-new-contact-container .button-container {
  margin-top: 50px;
  padding-left: 15px;
}
/* add new contact -END */

/* add new contact */

.add-new-contact-success {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding: 50px 0;
  background-color: white;
}

.add-new-contact-success .add-new-contact-success-container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  text-align: left;
}
/* add new contact success -END */


/* restricted */

.restricted {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 50px 0;
  background-color: white;
}


.restricted .restricted-notification-box {
  background-color: var(--notification-box);
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

/* restricted - END */

/* Form Styles */
form.add-new-contact {

}

.add-new-contact-container {
  
}
.form-sub-section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0;
}
fieldset {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: none;
  gap: 1rem;
}
label.field-label {
  font-weight: bold;
}
input {
  min-width: 300px;
  height: 25px;
  padding: 5px;
  border-radius: 2px;
  border: 1px solid black;
}

input[type="tel"], .react-datepicker__input-container input {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

}

select {
  min-width: 300px;
  padding: 10px 5px;
  border-radius: 2px;
  border: 1px solid black;
}
iframe{
  top:20px !important;
}

.form-control-file {
  height: auto;
}

/* Form Styles - END*/

.ig-btn-primary {
  background-color: var(--main-blue);
  border-color: var(--main-blue);
  &:hover{
    background-color:#093272;
    border-color:#093272;
  }
}

.page-link {
  color: var(--secondary-blue);
}
.page-item.active .page-link {
  background-color: var(--secondary-blue);
  border-color: var(--secondary-blue);
}
.page-link:focus {
  box-shadow: none;
}

.custom-btn, .custom-input {
  padding: .65rem 1.75rem
}

.custom-input {
  height: auto;
}